<template>

  <div id="announcement-view-panel">

    <!-- Heading -->
    <j-heading heading="Comments" />

    <!-- Comment Manager -->
    <CommentManager :source="announcement" />

  </div>
</template>

<script>

// Components
import CommentManager from '@/components/panels/comments/CommentManager'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('announcements')

export default {
  name: 'AnnouncementsViewPanel',
  // -------------
  // Components ==
  // -------------
  components: {
    CommentManager
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'announcement'
    ])
  }
}
</script>
